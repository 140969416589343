import { IconStar } from '@madpaws/design-system';
import getConfig from 'next/config';
import React from 'react';

import styles from './ProductReview.module.css';
import { PRODUCT_REVIEW_LINK } from '../constants';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

export const ProductReview = (): ReactElement => (
  <a href={PRODUCT_REVIEW_LINK} rel="noreferrer" target="_blank">
    <div className={styles.root}>
      <div className={styles.review}>
        <p className={styles.rate}>4.6</p>
        <IconStar />
      </div>
      <p>from 2,943 reviews on</p>
      <img
        alt="Product Review"
        height={24}
        src={`${publicRuntimeConfig.staticPath}/images/homepage/product-review-logo.svg`}
        width={139}
      />
      <div className={styles.winner}>
        <p>🏆 2024 Award Winner</p>
      </div>
    </div>
  </a>
);
